import {Injectable} from "@angular/core";

Injectable()

export class GlobalFile {
  // globallink = 'http://localhost:8091/mims-admin-service/';
  // loginlink = 'http://localhost:8091/';
  // base_path = 'http://localhost:8091/mims-admin-service/';
  // application_path = 'http://localhost:8080/';

  // // SMBT JAR
  globallink = 'https://smbtecampus.org:8091/';
  loginlink = 'https://smbtecampus.org:8091/';
  base_path = 'https://smbtecampus.org:8091/';
  application_path = 'https://smbtecampus.org:8445/';

  // // SMBT LIVE JAR
  // globallink = 'https://smbtecampus.org:8092/';
  // loginlink = 'https://smbtecampus.org:8092/';
  // base_path = 'https://smbtecampus.org:8092/';
  // application_path = 'https://smbtecampus.org:8445/';

  public localStorageItem(id: string): string {
    console.log("function called");
    return localStorage.getItem('userDetails');
    return localStorage.getItem('userAction');
  }

  public sessionStorageItem(): string {
    console.log("sessionStorageItem called");
    return localStorage.getItem('userAction');
  }
}
